import client from '../thinMintClient'
import { resolveFilePayload } from '../resolvers'
import {
  resolveEntityDocumentDown,
  resolveEntityDown,
  resolveEntityUp,
} from './resolver'
import { ServerEntity, EntityForm, ServerEntityDocument } from './types'

export const baseEndpoint = '/entities'

export const getEntities = () =>
  client
    .get(`${baseEndpoint}/`)
    .then((res: { data: { results: ServerEntity[] } }) =>
      res.data.results.map(resolveEntityDown)
    )

export const createEntity = (form: EntityForm) =>
  client
    .post(`${baseEndpoint}/`, resolveEntityUp(form))
    .then((res: { data: ServerEntity }) => {
      return resolveEntityDown(res.data)
    })

export const updateEntity = (entityId: string | number, form: EntityForm) =>
  client
    .put(`${baseEndpoint}/${entityId}/`, resolveEntityUp(form))
    .then((res: { data: ServerEntity }) => {
      return resolveEntityDown(res.data)
    })

export const getEntityDocuments = (entityId: string | number) =>
  client
    .get(`${baseEndpoint}/${entityId}/list_documents/`)
    .then((res: { data: ServerEntityDocument[] }) =>
      res.data.map(resolveEntityDocumentDown)
    )

export const uploadEntityDocument = (entityId: string | number, file: File) =>
  client.post(
    `${baseEndpoint}/${entityId}/upload_document/`,
    resolveFilePayload(file)
  )

export const removeEntityDocument = (
  entityId: string | number,
  fileId: string
) =>
  client
    .delete(`${baseEndpoint}/${entityId}/delete_document/${fileId}/`)
    .then((res) => {
      if (res.data !== 'Document deleted successfully') {
        throw new Error('Unable to delete document. Please try again.')
      }
      return res.data
    })
