import { resolvePhoneNumber } from '../resolvers'
import {
  Entity,
  EntityDocument,
  EntityForm,
  ServerEntity,
  ServerEntityDocument,
} from './types'
import { casingUtil } from '@/utils'

export const resolveEntityDown = ({ phone_number, ...rest }: ServerEntity) =>
  ({
    ...(casingUtil.snakeToCamel(rest) as Entity),
    phoneNumber: resolvePhoneNumber(phone_number),
  } as Entity)

export const resolveEntityUp = (entityForm: EntityForm) =>
  casingUtil.camelToSnake(entityForm) as ServerEntity

export const resolveEntityDocumentDown = ({
  documentid,
  entityid,
  documentTitle,
}: ServerEntityDocument) =>
  ({
    id: documentid,
    entityId: entityid,
    name: documentTitle,
  } as EntityDocument)
