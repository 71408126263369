import React, { useState, useEffect, useCallback, useRef } from 'react'
import { report } from '@/utils'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { PlayerWrapProps, VideoPlayer } from './types'
import { getVideoSegmentProperties, bindVideoPlayerApi } from './utils'

// There are numerous race conditions related to DOM updates and binding
// the youtube iframe API. Getting best results by limiting useEffect
// dependencies. Be careful updating.
/* eslint-disable react-hooks/exhaustive-deps */

const VideoBoxPlayerWrap: React.FC<PlayerWrapProps> = ({
  playerRef,
  id,
  video,
  doSegmentTracking = true,
  campaignSlug,
  onReady,
  onPlaying,
  onPaused,
  onEnded,
  service = 'youtube',
  children,
}) => {
  // If an error occurs in the youtube iframe api, then the iframe is removed
  // from the DOM, but React has no knowledge of the removal. This renderKey
  // is incremented in the iframe api error handler and is used to tell React
  // to rerender the iframe
  const [renderKey, setRenderKey] = useState(1)

  const videoPlayed = useRef('')

  const bindVideo = useCallback((video) => {
    if (playerRef.current) {
      // if the api is already bound to a video, need to destroy the instance
      // and re-call bindVideo after a slight delay in which the iframe
      // is re-rendered in the DOM
      playerRef.current.destroy()
      setRenderKey((cur) => cur + 1)
      setTimeout(() => {
        playerRef.current = null
        bindVideo(video)
      }, 100)
      return
    }

    playerRef.current = bindVideoPlayerApi(id, service)

    playerRef.current.load(video)

    playerRef.current.on('ready', () => {
      onReady?.()
    })

    playerRef.current.on('error', (err) => {
      let renderCount = 0
      setRenderKey((cur) => {
        renderCount = cur
        return cur + 1
      })

      if (err && renderCount < 2) {
        err.message = `${err.message}, ${video} (${service})`
        report.error(err)
      }
    })

    playerRef.current.on('unplayable', () => {
      const err = new Error(`Unplayable video ${video} (${service})`)
      report.error(err)
    })

    playerRef.current.on('playing', async () => {
      onPlaying?.()

      // avoid re-tracking if content paused/restarted
      if (videoPlayed.current === video) {
        return
      }

      videoPlayed.current = video

      if (doSegmentTracking) {
        SegmentHandler.track(
          'Video Content Started',
          await getVideoSegmentProperties(
            playerRef.current as VideoPlayer,
            campaignSlug,
            service
          )
        )
      }
    })

    playerRef.current.on('paused', async () => {
      onPaused?.()

      if (doSegmentTracking) {
        SegmentHandler.track(
          'Video Playback Paused',
          await getVideoSegmentProperties(
            playerRef.current as VideoPlayer,
            campaignSlug,
            service
          )
        )
      }
    })

    playerRef.current.on('ended', async () => {
      onEnded?.()

      if (doSegmentTracking) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { position, ...videoProperties } =
          await getVideoSegmentProperties(
            playerRef.current as VideoPlayer,
            campaignSlug,
            service
          )
        SegmentHandler.track('Video Content Ended', videoProperties)
      }
    })
  }, [])

  useEffect(() => {
    try {
      if (video) {
        bindVideo(video)
      }
    } catch (err) {
      setRenderKey((cur) => cur + 1)
      const error = err as Error
      error.message = `Error initializing iframe api for video ${video} (${service})`
      report.error(error)
    }
  }, [video])

  useEffect(() => {
    return () => {
      playerRef.current?.destroy()
      playerRef.current = null
    }
  }, [])

  return (
    <div key={renderKey} className="absolute inset-0">
      {children}
    </div>
  )
}

export default VideoBoxPlayerWrap
