import { SnakeToCamel } from '../types'

export enum EntityType {
  REVOCABLE_TRUST = 'Revocable Trust',
  IRREVOCABLE_TRUST = 'Irrevocable Trust',
  LIMITED_PARTNERSHIP = 'Limited Partnership',
  LLC = 'LLC',
  CORPORATION = 'Corporation',
  SELF_DIRECTED_IRA = 'Self Directed IRA',
}

export interface ServerEntity {
  id: number
  name: string
  email: string
  address1: string
  address2?: string
  city: string
  state: string
  ein?: string
  entity_type: string
  account_id: number
  phone_number: string
  postal_code: string
  has_ein: boolean
  escrow_agent_entity_id: string
}

export type Entity = SnakeToCamel<ServerEntity>

export type EntityForm = Partial<Entity>

export interface EntityDocument {
  id: string
  entityId: string
  name: string
}

export interface ServerEntityDocument {
  entityid: string
  documentid: string
  documentTitle: string
}
