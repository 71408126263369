import React, { useCallback } from 'react'
import PayoutModal from './PayoutModal'
import Button from '../Button/Button'
import Text from '../Text/Text'
import { Campaign } from '@/services/api/campaign'
import { Investment } from '@/services/api/investment'
import { getTipaltiIframeUrl } from '@/services/api/tipalti'
import { report } from '@/utils'
import { NotifiableError } from '@bugsnag/js'

export const REGISTRATION_MESSAGE = {
  NOT_PAYABLE: `Tipalti is our secure partner that handles distribution of funds and will process your payout, should one occur. You can register or edit your payout information with them by clicking the button below. Failing to register with Tipalti could delay the transfer of funds.`,
  PAYABLE: `You’re already registered with our secure partner, Tipalti. Should you need to edit your payout information, click the button below.`,
}

interface Props {
  campaign: Campaign
  className?: string
  investment?: Investment
}

const TipaltiPayout: React.FC<Props> = ({
  campaign,
  className,
  investment,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [tipaltiIframeUrl, setTiplatiIframeUrl] = React.useState('')
  const [error, setError] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const message = investment?.isTipaltiPayable
    ? REGISTRATION_MESSAGE.PAYABLE
    : REGISTRATION_MESSAGE.NOT_PAYABLE

  const handlePayoutButton = useCallback(async () => {
    if (!investment?.id) return

    try {
      setError('')
      setIsLoading(true)
      const { iframeUrl } = await getTipaltiIframeUrl(investment.id)
      setTiplatiIframeUrl(iframeUrl)
      setIsModalOpen(true)
    } catch (e) {
      report.error(e as NotifiableError)
      setError(
        'There was an error loading the Tipalti registration form. Please try again later.'
      )
    } finally {
      setIsLoading(false)
    }
  }, [investment?.id])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  if (!campaign.supportTipaltiPayback || !investment) {
    return null
  }

  return (
    <>
      <div className={className}>
        <Text as="h2" preset="heading.md" className="mb-3">
          Payout Method
        </Text>
        <Text as="p" preset="body.md" className="mb-6">
          {message}
        </Text>

        <Button
          variant="primary"
          size="sm"
          onClick={handlePayoutButton}
          disabled={isLoading}
        >
          Open Tipalti
        </Button>
        {error && (
          <Text as="p" preset="body.sm" className="mt-3 text-red">
            {error}
          </Text>
        )}
      </div>
      <PayoutModal
        iframeUrl={tipaltiIframeUrl}
        isModalOpen={isModalOpen}
        setIsModalOpen={handleCloseModal}
      />
    </>
  )
}

export default TipaltiPayout
