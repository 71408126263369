import React, { useEffect, useRef } from 'react'
import CardModal from '../CardModal/CardModal'
import Iframe from '../Iframe'

interface Props {
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
  iframeUrl: string
}

const PayoutModal: React.FC<Props> = ({
  iframeUrl,
  isModalOpen,
  setIsModalOpen,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    function tipaltiHandler(event: any) {
      if (event.data.TipaltiIframeInfo && iframeRef.current) {
        iframeRef.current.style.height = `${event.data.TipaltiIframeInfo.height}px`
        iframeRef.current.style.width = `${event.data.TipaltiIframeInfo.width}px`
      }
    }

    window.addEventListener('message', tipaltiHandler, false)

    return () => {
      window.removeEventListener('message', tipaltiHandler)
    }
  }, [])

  return (
    <CardModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} width="w-full">
      <CardModal.Body className="md:w-[80vw] md:max-w-4xl pb-8">
        <Iframe
          ref={iframeRef}
          src={iframeUrl}
          fadeInOnLoad={false}
          className="max-h-[95vh]"
        />
      </CardModal.Body>
    </CardModal>
  )
}

export default PayoutModal
